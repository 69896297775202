import styled from "styled-components";
import { motion } from "framer-motion";

export const SectionContainer = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AboutContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 60px;
  margin-bottom: 30px;
  max-width: 1180px;
  width: 100%;

  @media screen and (min-width:768px) and (max-width: 1200px) {
    align-items: center;
    justify-content: space-around;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    align-items: center;
    flex-direction:column;
    justify-content: space-around;
    margin-bottom: 0px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

export const MainText = styled(motion.h3)`
  max-width: 500px;
  width: 100%;
  font-family: Rubik;
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.04em;
  text-align: left;
  color: rgba(223, 223, 223, 1);
  margin-left: 16px;

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-top: 20px;
    text-align: left;
    font-size: 32px;

  }
  
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 8%;
  left: 10%;
  margin-right: auto;

  @media screen and (min-width:768px) and (max-width: 1200px) {
    position: static;
    margin: 0 30px 10px 30px;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    position: static;
    margin: 0 30px 10px 30px;
  }
`;

export const Logo = styled.img`
  width: 250px;
  height: 112px;
`;

export const PartnerInfoContainer = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 64px;

  @media screen and (min-width:768px) and (max-width: 1200px) {
    position: static;
    margin: 0 30px 10px 30px;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    flex-direction: column;
  }
  
`;

export const ImageText = styled.div`
  position: absolute;
  top: 45px;
  left: 45px;
  margin-bottom: 26px;
  font-family: Neue Machina-Light;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;

  @media screen and (min-width:768px) and (max-width: 1200px) {
    top: 32px;
    left: 32px;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    top: 24px;
    left: 24px;
  }
`;


export const Image = styled.img`
position: relative;  
width: 100%;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s;

  @media screen and (min-width:768px) and (max-width: 1200px) {
    width: 325px;
    height: 400px;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    width: 280px;
    height: 320px;
  }
`;

export const HoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  filter: brightness(1.5);
  transition: opacity 0.3s;
  @media screen and (min-width:768px) and (max-width: 1200px) {
    width: 325px;
    height: 400px;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    width: 280px;
    height: 320px;
  }
`;

export const SvgIcon = styled.svg`
  width: 45px;
  height: 45px;
`;

export const GamerImage = styled(motion.div)`
  position: relative;
  width: 525px;
  height: 600px;
  margin-top: 45px;
  

  &:hover ${Image} {
    opacity: 0;
  }
  &:hover ${HoverImage} {
    opacity: 1;
  }

  ${SvgIcon} {
    position: absolute;
    bottom: 45px; 
    left: 45px;   
    width: 45px;
    height: 45px;
    transition: fill 0.3s ease-in-out;
  }

  @media screen and (min-width:768px) and (max-width: 1200px) {
    width: 325px;
    height: 400px;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    width: 280px;
    height: 320px;
    margin-top: 0;
  }
`;

export const PartnerImage = styled(motion.div)`
  position: relative;
  width: 525px;
  height: 600px;

  &:hover ${Image} {
    opacity: 0;
  }
  &:hover ${HoverImage} {
    opacity: 1;
  }

  ${SvgIcon} {
    position: absolute;
    bottom: 45px; 
    left: 45px;   
    width: 45px;
    height: 45px;
    
  }

  @media screen and (min-width:768px) and (max-width: 1200px) {
    width: 325px;
    height: 400px;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    width: 280px;
    height: 320px;
    
  }
`;

export const ImgTitle = styled.h1`
  font-family: Neue Machina-Light;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 26px;

  @media screen and (min-width:768px) and (max-width: 1200px) {
    font-size: 32px;
    margin: 0;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    font-size: 24px;
    margin: 0;
  }

`;

export const ImgText = styled.p`
  font-family: Neue Machina-Light;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin:0;
  text-align: left;
  color:rgba(206, 206, 206, 1);

  @media screen and (min-width:768px) and (max-width: 1200px) {
    font-size: 16px;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    font-size: 14px;
    margin: 0;
  }
`;

export const ImgButton = styled.button`
  font-family: Neue Machina-Light;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  background: transparent;
  width: 200px;
  height: 50px;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 30px;
  padding: 0;
  position: relative;
  overflow: hidden;
  transition: color 0.2s ease-in-out, font-size 0.2s ease-in-out;

  &:hover {
    font-size: 16px;
  }

  @media screen and (min-width:768px) and (max-width: 1200px) {
    font-size: 14px;
    margin-top: 20px;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    font-size: 14px;
    margin: 0;
  }
`;
