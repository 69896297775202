import React from "react";
import logo from '../../assets/logoNormal.svg';
import { HeaderWrapper, 
    Logo} from "./header.styled";
import LanguageSelect from "../languageSelect/languageSelect";

const Header = () => {

  return (
    <HeaderWrapper>
      <Logo src={logo} alt="Logo Miraplay" />
      <LanguageSelect />
    </HeaderWrapper>
  );
};

export default Header;
