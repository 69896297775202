export const lang = {
    ua: {
        cards: [{
            title: ["платим 0,045 грн/хв"],
            text: ["Платимо 24/7 за кожну хвилину ", "вашого пристрою у нас в сервісі"],
            hash: "# вигідно",
        }, {
            title: ["дохід від 2 000 грн"],
            text: ["Щомісячно з кожного комп'ютера",],
            hash: "# просто",
        }],
    },
    ru: {
        cards: [{
            title: ["платим 0,045 грн/мин"],
            text: ["Платим 24/7 за каждую минуту ", "вашего устройства у нас в сервисе"],
            hash: "# выгодно",
        },{
            title: ["доход от 2 000 грн"],
            text: ["Ежемесячно с каждого компьютера",],
            hash: "# просто",
        }],
    }, 
    en: {
        cards: [{
            title: ["pay 0.045 UAH/min"],
            text: ["We pay 24/7 for each minute ", "of your device in our service"],
            hash: "# beneficial",
        }, {
            title: ["income from 2,000 UAH"],
            text: ["Monthly from each computer",],
            hash: "# simple",
        }],
    }
};