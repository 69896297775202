export const lang = {
    ua: {
      title: ["Все, що потрібно - ", "комп'ютер і", "доступ до", "інтернету"],
      text: ["Ознайомтеся з мінімальними ", "вимогами до обладнання для партнерів"],
  
      demandTitle: ["Мінімальні вимоги для партнерства:"],
      demandText: [
        "Процесор: AMD Ryzen / Intel Core i. Не менше 6 фізичних ядер 3.0+ GHz",
        "SSD: не менше 1 Tb",
        "Оперативна пам'ять: не менше 22 Gb",
        "Відеокарта: 30-я серія NVIDIA і новіше, не слабше NVIDIA RTX 3060",
        "Флешка на 8 Gb, для початкового розгортання",
        "Провідний інтернет не менше 100 Мбіт/с на кожну віртуальну машину",
      ],
    },
  
    ru: {
      title: ["Все, что нужно - ", "компьютер и", "доступ к", "интернету"],
      text: ["Ознакомьтесь c минимальными ", "требованиями к железу для партнеров"],
  
      demandTitle: ["Минимальные требования для партнерства:"],
      demandText: [
        "Процессор: AMD Ryzen / Intel Core i. Не менее 6 физических ядер 3.0+ GHz",
        "SSD: не менее 1 Tb",
        "Оперативная память: не менее 22 Gb",
        "Видеокарта: 30-я серия NVIDIA и новее, не слабее NVIDIA RTX 3060",
        "Флешка на 8 Gb, для начального развертывания",
        "Проводной интернет не менее 100 Мбит/с на каждую виртуальную машину",
      ],
    },
  
    en: {
      title: ["All you need is a ", "computer and", "internet access"],
      text: ["Check out the minimum ", "hardware requirements for partners"],
  
      demandTitle: ["Minimum requirements for partnership:"],
      demandText: [
        "Processor: AMD Ryzen / Intel Core i. At least 6 physical cores 3.0+ GHz",
        "SSD: at least 1 Tb",
        "RAM: at least 22 Gb",
        "Graphics card: 30th series NVIDIA or newer, not weaker than NVIDIA RTX 3060",
        "8 Gb flash drive, for initial deployment",
        "Wired internet at least 100 Mbps per virtual machine",
      ],
    },
  };
  