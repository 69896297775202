export const lang = {
    ua: {  
        mainTitle: ["-це", "сервіс, який дозволяє", "заробляти", "і насолоджуватися грою"],

        gamerTitle: ["Геймери"],
        gamertText: ["Мають можливість грати на", "слабкому ПК і Mac в кращі ігри"],
        gamerBtn: "ПОЧАТИ ГРАТИ",

        partnerTitle: ["Партнери"],
        partnerText: ["Отримують винагороду за", "оренду свого комп'ютера"],
        partnerBtn: "ПОЧАТИ ЗАРОБЛЯТИ"
        
    },
    ru: {
        mainTitle: ["-это", "сервис, позволяющий", "зарабатывать", "и наслаждаться игрой"],

        gamerTitle: ["Геймеры"],
        gamertText: ["Получают возможность играть на", "слабом ПК и Mac в лучшие игры"],
        gamerBtn: "НАЧАТЬ ИГРАТЬ",

        partnerTitle: ["Партнеры"],
        partnerText: ["Получают вознаграждение за", "аренду своего компьютера"],
        partnerBtn: "НАЧАТЬ ЗАРАБАТЫВАТЬ"
    },
    en: {
        mainTitle: ["-this is", "a service that allows you to", "earn", "and enjoy gaming"],

        gamerTitle: ["Gamers"],
        gamertText: ["Get the opportunity to play on", "a weak PC and Mac in the best games"],
        gamerBtn: "START PLAYING",

        partnerTitle: ["Partners"],
        partnerText: ["Get rewarded for", "renting out your computer"],
        partnerBtn: "START EARNING"
    },
};
 