import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { lang } from './lang';
import BackgroundImage from '../../assets/diablo-im-crew-dfa8 1.png';
import logo from '../../assets/logoNormal.svg';
import BlueRock from '../../assets/pngfind 3.png';
import PurpleRock from '../../assets/pngfind 2.png';
import { SectionContainer, Content, TextContainer, Title, BottomText, FormContainer, FormTitle, FormDescription, Form, ShortRow, LongRow, Input, TextArea, Button, ImgBack, Blue, Purple, LogoContainer, Logo } from './PartnerForm.styled';
import { useTrail } from '@react-spring/web';
import { useMeasure } from 'react-use';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Notiflix from 'notiflix';

const fast = { tension: 1200, friction: 40 };
const slow = { mass: 10, tension: 200, friction: 50 };
const trans = (x, y) => `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`;
const converttrans = (x, y) => `translate3d(${-x}px,${-y}px,0) translate3d(50%,50%,0)`;

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  city: Yup.string().required('City is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone is required'),
  message: Yup.string(),
});
Notiflix.Report.init({
  success: {
    svgColor: '#32c682',
    titleColor: '#1e1e1e',
    messageColor: '#242424',
    buttonBackground: '#32c682',
    buttonColor: '#fff',
    backOverlayColor: 'rgba(50,198,130,0.2)',
  }})
const PartnerForm = () => {
  const l = useTranslation(lang);

  const [trail, api] = useTrail(1, (index) => ({
    xy: [200, 100],
    config: index === 1 ? fast : slow,
  }));

  const [ref, { left, top }] = useMeasure();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      city: '',
      phone: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch('https://api.miraplay.cloud/computer_landlords/email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          const result = await response.json();
          console.log(result);
          Notiflix.Report.success(
            l.notificationTitle,
            l.notificationMessage,
            'Ok',

          );
          formik.resetForm();
        } else {
          const errorResult = await response.json();
          console.error(errorResult);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
  });

  const handleMouseMove = (e) => {
    api.start({ xy: [e.clientX - left, e.clientY - top] });
  };

  return (
    <SectionContainer ref={ref} onMouseMove={handleMouseMove} id='partnerform'>
      <ImgBack src={BackgroundImage} alt="Background" />
      <LogoContainer>
        <Logo src={logo} alt="Your SVG" />
      </LogoContainer>
      <Content>
        <TextContainer>
          <Title>
            {l.title[0]} <br /> {l.title[1]}
          </Title>
          <BottomText>
            {l.text[0]}<br />
            {l.text[1]}<br />
            {l.text[2]}<br />
          </BottomText>
        </TextContainer>

        {trail.map((props, index) => (
          <Purple key={index} style={{ transform: props.xy.to(converttrans) }} src={PurpleRock} alt="purple rock" />
        ))}

        {trail.map((props, index) => (
          <Blue key={index} style={{ transform: props.xy.to(trans) }} src={BlueRock} alt="blue rock" />
        ))}

        <FormContainer>
          <FormTitle>
            {l.formTitle[0]}<br />
            {l.formTitle[1]}
          </FormTitle>
          <FormDescription>
            {l.formText[0]}<br />
            {l.formText[1]}
          </FormDescription>
          <Form onSubmit={formik.handleSubmit}>
  <ShortRow>
    <Input
      type="text"
      name="name"
      value={formik.values.name}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      placeholder={l.placeholders.name}
      className={formik.touched.name ? (formik.errors.name ? 'inputErr' : 'inputSuccess') : ''}
    />
    <Input
      type="text"
      name="city"
      value={formik.values.city}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      placeholder={l.placeholders.city}
      className={formik.touched.city ? (formik.errors.city ? 'inputErr' : 'inputSuccess') : ''}
    />
  </ShortRow>
  <LongRow>
    <Input
      type="email"
      name="email"
      value={formik.values.email}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      placeholder={l.placeholders.email}
      className={formik.touched.email ? (formik.errors.email ? 'inputErr' : 'inputSuccess') : ''}
    />
    <Input
      type="tel"
      name="phone"
      value={formik.values.phone}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      placeholder={l.placeholders.phone}
      className={formik.touched.phone ? (formik.errors.phone ? 'inputErr' : 'inputSuccess') : ''}
    />
    <TextArea
      name="message"
      value={formik.values.message}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      placeholder={l.placeholders.message}
      className={formik.touched.message ? (formik.errors.message ? 'inputErr' : 'inputSuccess') : ''}
    />
  </LongRow>
  <Button type="submit" disabled={!formik.isValid}>
    {l.Btn}
  </Button>
</Form>
        </FormContainer>
      </Content>
    </SectionContainer>
  );
};

export default PartnerForm;
