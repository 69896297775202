import React from 'react';
import Header from '../components/Header/header';
import  HeadSection  from '../components/HeadSection/HeadSection';
import Benefits from '../components/Benefits/Benefits';
import Client from '../components/ClientsSection/ClientsSection';
import Demand from '../components/Demand/Demand';
import PartnerForm from '../components/PartnerForm/PartnerForm';
import Footer from '../components/Footer/Footer';

const MainPage = () => {
    
    return (
      <>
      <Header/>
      <HeadSection/>
      <Benefits/>
      <Client/>
      <Demand/>
      <PartnerForm/>
      <Footer/>
      </>
    );
};

export default MainPage;