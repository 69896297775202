export const lang = {
    ua: {  
        title: ["Почніть заробляти ", " від 75$", " на оренді комп'ютерів прямо зараз"],
        text: ["Підключайте свої геймінгові ПК до Miraplay і ", " заробляйте  ", " від 2 000 грн ", " за кожен пристрій "],
        mainBtn: "ПОЧАТИ ЗАРОБЛЯТИ",
        secondBtn: "ДЕТАЛЬНІШЕ",
    },
    ru: {
        title: ["Начните зарабатывать ", " от 75$ ", " на аренде  компьютеров прямо сейчас "],
        text: ["Подключайте свои геймерские ПК к Miraplay и", "зарабатывайте", " от 2 000 грн", " за каждое устройство"],
        mainBtn: "НАЧАТЬ ЗАРАБАТЫВАТЬ",
        secondBtn: "ПОДРОБНЕЕ",
    },
    en: {
        title: ["Start earning ", " from 75$", "  on renting computers right now "],
        text: ["Connect your gaming PCs to Miraplay and", " earn  ", " from 2,000 UAH", " for each device"],
        mainBtn: "START EARNING",
        secondBtn: "LEARN MORE",
    },
};