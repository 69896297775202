export const lang = {
    ua: {
        title: ["Працювати з нами ", "дуже вигідно"],
        text: ["Спочатку вам потрібно ввести", "свої дані і слідувати", "інструкціям в отриманому листі"],

        formTitle: ["Залиште свої дані ", "і почніть заробляти"],
        formText: ["Ми відправимо вам інструкцію та умови", "на пошту"],

        placeholders: {
            name: "Ім'я",
            city: "Місто",
            email: "Електронна пошта",
            phone: "Номер телефону",
            message: "Повідомлення (необов'язково)"
        },

        Btn: "ПОЧАТИ ЗАРОБЛЯТИ",

        notificationTitle:"Ми вже отримали ваш лист, ВОГОНЬ!",
        notificationMessage:"Зараз все прочитаємо і кинемо відповідь, трішечки почекати",
        
    },
    ru: {
        title: ["Работать с нами ", "очень выгодно"],
        text: ["Для начала, вам понадобится ввести", "свои данные и проследовать", "инструкции в полученном письме"],
    
        formTitle: ["Оставьте свои данные ", "и начните зарабатывать"],
        formText: ["Мы отправим вам инструкцию и условия", "на почту"],
    
        placeholders: {
            name: "Имя",
            city: "Город",
            email: "Электронная почта",
            phone: "Номер телефона",
            message: "Сообщение(необязательно)"
        },
    
        Btn: "НАЧАТЬ ЗАРАБАТЫВАТЬ",

        notificationTitle:"Мы уже получили ваше письмо, АГОНЬ!",
        notificationMessage:"Сейчас мы все прочитаем и ответим. Подождите немного.",
    },
    en: {
        title: ["Working with us is ", "very profitable"],
        text: ["To start, you'll need to enter", "your details and follow", "the instructions in the received email"],

        formTitle: ["Leave your details ", "and start earning"],
        formText: ["We will send you instructions and conditions", "to your email"],

        placeholders: {
            name: "Name",
            city: "City",
            email: "Email",
            phone: "Phone Number",
            message: "Message (optional)"
        },

        Btn: "START EARNING",

        notificationTitle:"Oh, awesome! ",
        notificationMessage:"We will read and respond shortly. Please wait a bit",
    },
};