import styled from "styled-components";

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  
  height: 50px;
  z-index: 5;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;


 export const Logo = styled.img`
  max-height: 100%;
`;

