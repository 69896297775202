import styled from "styled-components";
import { motion } from "framer-motion";

export const DemandSection = styled.section`
  width: 100%;
  max-width: 1180px;
  height: 600px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 60px auto;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: 975px;;
    margin-top: 60px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding-left: 150px;
  align-self: center;
  
  
  @media (max-width: 768px) {
    gap: 0;
    padding-left: 0;
    align-self: center; 
  }
`;


export const DemandTitle = styled(motion.h2)`
  font-family: Rubik;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 20px;
  color: #fff;
  @media screen and (min-width:768px) and (max-width: 1200px) {
    font-size: 32px;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    font-family: Rubik;
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.04em;
    text-align: left;
    margin-top: 60px;
    margin-left: 20px;

  }
`;
export const DemandText = styled(motion.p)`
font-family: Rubik;
font-size: 18px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: rgba(175, 175, 175, 1);

@media screen and (min-width:320px) and (max-width: 768px) {
  margin-left: 20px;

}
`;

export const DemandList = styled.ul`
  width: 550px;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  padding-left: 150px;

  @media screen and (min-width:768px) and (max-width: 1200px) {
    width: 450px;
    padding-left: 0;
  }

  @media screen and (min-width:320px) and (max-width: 768px) {
    width: 90%;
    padding-left: 0;
  }
`;

export const DemandListTitle = styled(motion.h3)`
  font-family: Rubik;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 32px;
  color: #fff;
`;

export const DemandListItem = styled(motion.li)`
  display: flex;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
  color: rgba(206, 206, 206, 1);

  & > img {
    margin-right: 10px;
  }
`;

export const IconSvg = styled.img`
  max-width: 20px;
  max-height: 20px;
`;
