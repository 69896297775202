import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { lang } from './lang';
import goldenAccept from '../../assets/golden-accept.svg'
import {
  DemandSection,
  Container,
  DemandText,
  DemandTitle,
  DemandListTitle,
  DemandList,
  DemandListItem,
  IconSvg
} from './Demand.styled';


const Demand = () => {
  const l = useTranslation(lang);
  
  const animation = {
    
    hidden: {
        y: -50,
        opacity: 0,
    },
    visible: (custom = 0) => ({
        y: 0,
        opacity: 1,
        transition: {delay: custom * 0.15, duration: 1},

    }),
  }
  
  const animationDown = {
    
    hidden: {
        x: -50,
        opacity: 0,
    },
    visible: (custom = 0) => ({
        x: 0,
        opacity: 1,
        transition: {delay: custom * 0.15, duration: 1},

    }),
  }

  const splitTitle = l.title.map((line, index) => (
    <span key={index}>
      {index > 0 && <br />}
      {line}
    </span>
  ));

  return (
    <DemandSection id='demand'>
      <Container>
        <DemandTitle variants={animation}
                initial="hidden"
                whileInView="visible"
                custom={1}>
        {splitTitle}
        </DemandTitle>

        <DemandText variants={animation}
                initial="hidden"
                whileInView="visible"
                custom={1}>
          {l.text[0]}<br />
          {l.text[1]}
        </DemandText>
      </Container>
      <DemandList>

        <DemandListTitle  variants={animationDown}
          initial="hidden"
          whileInView="visible"
          custom={2}>
            {l.demandTitle}
        </DemandListTitle>

        {l.demandText.map((item, index) => (
          <DemandListItem key={index} 
          variants={animationDown}
          initial="hidden"
          whileInView="visible"
          custom={3}>
            <IconSvg src={goldenAccept} alt="golden accept sign"/>
            {item}
          </DemandListItem>
        ))}
      </DemandList>
    </DemandSection>
  );
};

export default Demand;
