import React from "react";
import logo from '../../assets/logoNormal.svg';
import gamerImage from '../../assets/gamer.png';
import gamerHoverImage from '../../assets/gamer hover.png';
import partnerImage from '../../assets/partner.png';
import partnerHoverImage from '../../assets/partner hover.png';
import { lang } from "./lang";
import { useTranslation } from "../../hooks/useTranslation";
import sprite from '../../assets/sprite.svg';
import {
  SectionContainer,
  LogoContainer,
  AboutContainer,
  MainText,
  TextContainer,
  PartnerInfoContainer,
  GamerImage,
  PartnerImage,
  Logo,
  Image,
  HoverImage,
  ImageText,
  ImgTitle,
  ImgText,
  ImgButton,
  SvgIcon
} from "./ClientsSection.styled";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";


const Client = () => {
  const l = useTranslation(lang);

  const animation = {
    
    hidden: {
        x: -50,
        opacity: 0,
    },
    visible: (custom = 0) => ({
        x: 0,
        opacity: 1,
        transition: {delay: custom * 0.15, duration: 1},

    }),
  }


  return (
    <SectionContainer>

      <AboutContainer>
      <LogoContainer>
        <Logo src={logo} alt="Your SVG" />
      </LogoContainer>

      <TextContainer>
      <MainText variants={animation}
                initial="hidden"
                whileInView="visible"
                custom={1}>
       {l.mainTitle[1]} {l.mainTitle[2]} {l.mainTitle[3]}
      </MainText>
      </TextContainer>

      </AboutContainer>

      <PartnerInfoContainer>
    
        <GamerImage whileHover={{ scale: 1.1 }}>
        <Image src={gamerImage} alt="Dark Gamer Image" />
        <HoverImage src={gamerHoverImage} alt="Light Gamer Image" />
          <ImageText>
            <ImgTitle>{l.gamerTitle}</ImgTitle>
            <ImgText>{l.gamertText[0]}</ImgText>
            <ImgText>{l.gamertText[1]}</ImgText>
            <Link to="https://miraplay.cloud" target="_blank" rel="noopener noreferrer">
            <ImgButton>{l.gamerBtn}</ImgButton>
            </Link>
          </ImageText>
          <SvgIcon>
          <use href={sprite +"#icon-gamer-group"} />
          </SvgIcon>
        </GamerImage>

        <PartnerImage whileHover={{ scale: 1.1 }}>
        <Image src={partnerImage} alt="Dark Image" />
        <HoverImage src={partnerHoverImage} alt="Light Image" />
          <ImageText>
            <ImgTitle>{l.partnerTitle}</ImgTitle>
            <ImgText>{l.partnerText[0]}</ImgText>
            <ImgText>{l.partnerText[1]}</ImgText>
            <HashLink smooth to="#partnerform">
            <ImgButton>{l.partnerBtn}</ImgButton>
            </HashLink>
          </ImageText>
          <SvgIcon>
          <use href={sprite +"#icon-partner-group"} />
          </SvgIcon>
        </PartnerImage>

      </PartnerInfoContainer>

    </SectionContainer>
  );
};

export default Client;
