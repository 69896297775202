import React from "react";
import { SectionContainer,
         InfoContainer, 
         InfoBlock } 
    from "./Benefits.styled";
import { lang } from "./lang";
import {useTranslation} from "../../hooks/useTranslation";

 const Benefits = () => {
  const l = useTranslation(lang);

  return (
    <SectionContainer>
      <InfoContainer>
          {l.cards.map((card, index) => (
            <InfoBlock key={index}>
            <h2>{card.title}</h2>
            <p>{card.text[0]} <br/>{card.text[1]} </p>
            <p>{card.hash}</p>
          </InfoBlock>
          ))}
        </InfoContainer>

    </SectionContainer>
  );
};

export default Benefits;