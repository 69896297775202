import React, { useRef, useLayoutEffect } from 'react';
import { motion } from 'framer-motion-3d';
import {TextureLoader } from 'three';
import * as THREE from 'three';
import { Canvas, useThree } from '@react-three/fiber';
import { useSmoothTransform } from './useSmoothTransform';

import DollarImage from '../../assets/dollarsSign.png';

const spring = { stiffness: 600, damping: 30 };

export function Material() {
  return <meshStandardMaterial color="#1b0c69" metalness={4} roughness={0.01} />;
}

export function Lights() {
  return (
    <>
      <ambientLight intensity={0.5} />
      <spotLight color="#61dafb" position={[-10, -10, -10]} intensity={0.2} />
      <spotLight color="#61dafb" position={[-10, 0, 15]} intensity={0.8} />
      <spotLight color="#61dafb" position={[-5, 20, 2]} intensity={0.5} />
      <spotLight color="#f2056f" position={[15, 10, -2]} intensity={2} />
      <spotLight color="#f2056f" position={[15, 10, 5]} intensity={1} />
      <spotLight color="#b107db" position={[5, -10, 5]} intensity={0.8} />
    </>
  );
}

export function Shapes({ isHover, isPress, mouseX, mouseY }) {
  const lightRotateX = useSmoothTransform(mouseY, spring, (v) => -v / 140);
  const lightRotateY = useSmoothTransform(mouseX, spring, (v) => -v / 140);

  return (
    <Canvas shadows dpr={[1, 2]} resize={{ scroll: false, offsetSize: true }}>
      <Camera mouseX={mouseX} mouseY={mouseY} />
      <motion.group center={[0, 0, 0]} rotation={[lightRotateX, lightRotateY, 0]}>
        <Lights />
      </motion.group>
      <motion.group
        initial={false}
        animate={isHover ? 'hover' : 'rest'}
        dispose={null}
        variants={{
          hover: { z: isPress ? -0.9 : 0 },
        }}
      >
        <Dollar image={DollarImage} />
        <PC image={DollarImage} />
        <Coin image={DollarImage} />
        <Bill image={ DollarImage } />
      </motion.group>
    </Canvas>
  );
}

export function Dollar({ image }) {
  return (
    <motion.mesh position={[-1.1, -0.8, 0]} variants={{ hover: { z: 1.6 } }}>
      <planeGeometry args={[0.8, 0.8]} />
      <meshBasicMaterial map={new TextureLoader().load(image)} transparent side={THREE.DoubleSide} />
    </motion.mesh>
  );
}

export function PC({ image }) {
  return (
    <motion.mesh
      position={[-1.5, 0.4, 1]}
      rotation={[0.5, 0, 0.3]}
      variants={{
        hover: {
          z: 1.4,
          x: -1.6,
          rotateX: -0.1,
          rotateZ: 0.1,
        },
      }}
    >
      <planeGeometry args={[0.8, 0.8]} />
      <meshBasicMaterial map={new TextureLoader().load(image)} transparent side={THREE.DoubleSide} />
    </motion.mesh>
  );
}

export function Coin({ image }) {
  return (
    <motion.mesh
      position={[0.1, 0.4, 0]}
      rotation={[-0.1, 0.1, 0]}
      variants={{
        hover: {
          y: 0.5,
          z: 1.9,
          
        },
      }}
    >
      <planeGeometry args={[0.8, 0.8]} />
      <meshBasicMaterial map={new TextureLoader().load(image)} transparent  side={THREE.DoubleSide} />
    </motion.mesh>
  );
}

export function Bill({ image }) {
  return (
    <motion.mesh position={[2, 0.2, 0]} variants={{ hover: { z: 1.4 } }}>
      <planeGeometry args={[0.8, 0.8]} />
      <meshBasicMaterial map={new TextureLoader().load(image)} transparent side={THREE.DoubleSide} />
    </motion.mesh>
  );
}

function Camera({ mouseX, mouseY, ...props }) {
  const cameraX = useSmoothTransform(mouseX, spring, (x) => x / 350);
  const cameraY = useSmoothTransform(mouseY, spring, (y) => -y / 350);

  const set = useThree(({ set }) => set);
  const camera = useThree(({ camera }) => camera);
  const size = useThree(({ size }) => size);
  const scene = useThree(({ scene }) => scene);
  const cameraRef = useRef();

  useLayoutEffect(() => {
    const { current: cam } = cameraRef;
    if (cam) {
      cam.aspect = size.width / size.height;
      cam.updateProjectionMatrix();
    }
  }, [size, props]);

  useLayoutEffect(() => {
    if (cameraRef.current) {
      const oldCam = camera;
      set(() => ({ camera: cameraRef.current }));
      return () => set(() => ({ camera: oldCam }));
    }
  }, [camera, cameraRef, set]);

  useLayoutEffect(() => {
    return cameraX.onChange(() => camera.lookAt(scene.position));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraX]);

  return (
    <motion.perspectiveCamera ref={cameraRef} fov={90} position={[cameraX, cameraY, 3.8]} />
  );
}