import React, { useRef } from "react";


import video from "../../assets/head_bd_video.mp4";
import { lang } from "./lang";
import {useTranslation} from "../../hooks/useTranslation";

import { SectionContainer, 
  VideoBackground,
   TextOverlay, 
   SlideTitle,
   DetailButton, 
   ButtonContainer,
   SlideText,
   SlideSpan} from "./HeadSection.styled";
import { HashLink } from "react-router-hash-link";
import Button from "../3d button/button";



 const HeadSection = () => {
  const vidRef = useRef();
  const l = useTranslation(lang);

  const animation = {
    
    hidden: {
        y: -50,
        opacity: 0,
    },
    visible: (custom = 0) => ({
        y: 0,
        opacity: 1,
        transition: {delay: custom * 0.15, duration: 1},

    }),
};

  return (
    <SectionContainer>
      <VideoBackground
        src={video}
        ref={vidRef}
        type="video/mp4"
        muted
        loop
        autoPlay
        playsInline
        onLoad={() => vidRef.current.play()}
      />
      
      <TextOverlay>
          <SlideTitle variants={animation}
                      initial="hidden"
                      animate="visible"
                      custom={4}>
          {l.title[0].toUpperCase()}
          <span>{l.title[1]}</span> 
          {  l.title[2].toUpperCase()}
          
          </SlideTitle>

          <SlideText variants={animation}
                      initial="hidden"
                      animate="visible"
                      custom={3}>
            {l.text[0]}
          </SlideText>

          <SlideText variants={animation}
                      initial="hidden"
                      animate="visible"
                      custom={2}>
            {l.text[1]} <SlideSpan>{l.text[2]}</SlideSpan> {l.text[3]}
          </SlideText>
          
          <ButtonContainer variants={animation}
                           initial="hidden"
                           animate="visible"
                           custom={1}>
          <HashLink smooth to="#partnerform" style={{ textDecoration: 'none' }}>
          <Button title={l.mainBtn} />
          </HashLink>
          <HashLink smooth to="#demand">
            <DetailButton>{l.secondBtn}</DetailButton>
          </HashLink>
          </ButtonContainer>
      </TextOverlay>  
    </SectionContainer>
  );
};

export default HeadSection;