import React from 'react';
import {Routes, Route} from 'react-router-dom';
import MainPage from './pages/mainPage'; 
import Demand from './components/Demand/Demand';
import PartnerForm from './components/PartnerForm/PartnerForm';
 export const App = () => {
  return (
    <Routes>
        <Route path="/" element={<MainPage/>} />
        <Route path="demand" element={<Demand/>} />
        <Route path="partnerform" element={<PartnerForm/>} />
    </Routes>
  );
};

