import styled from "styled-components";

export const SectionContainer = styled.section`
  position: relative;
  height: 250px;
  max-width: 1180px;
  margin: 60px auto;
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    height: 250px;
  }
  @media screen and (min-width: 320px) and (max-width: 750px) {
    height: 720px;
    flex-direction: column;
    align-items: center;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  width: 90%;
  color: #fff;
  z-index: 1;

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media screen and (min-width: 320px) and (max-width: 750px) {
    height: 720px;
    width:100%;
    flex-direction: column;
    align-items: center;
  }
`;

export const InfoBlock = styled.div`
  flex: 1;
  text-align: center;
  width: calc((100% - 20px) / 3); 
  margin-right: 10px; 
  box-sizing: border-box;
  height: 284px;
  position: relative; 

  @media (min-width: 1200px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
    max-width: 100%;
    width: calc((100% - 20px) / 2); 
  }

  @media (max-width: 500px) {
    width: calc(100% - 70px); 
  }

  h2 {
    margin-bottom: 16px;
    font-family: 'Neue Machina-Light';
    font-size: 40px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  p {
    font-family: 'Neue Machina-Light';
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    color: rgba(175, 175, 175, 0.8);
  }

  p:last-child {
    font-family: 'Neue Machina-Light';
    font-size: 30px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #fff;
    margin-bottom: 40px;
    @media (max-width: 768px) {
      bottom: -25px;
      left: 20%;
    }
  }
`;
